<template>
  <div class="container">
    <PublicHeader :totalName="$t('news').title" :noLeft="noLeft" />
    <van-pull-refresh
      v-model="refreshing"
      :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText"
      :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded"
      @refresh="onRefresh"
    >
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div
          class="new-list"
          :class="{ importantNews: item.NewsType === 'ImportantNews' }"
          v-for="(item, index) in list"
          :key="index"
        >
          <p class="time">{{ item.Time  | getLocalTime }}</p>
          <p class="title">
            {{ item.Title }}
          </p>
          <p class="content" v-html="item.Content" />
        </div>
      </van-list>
    </van-pull-refresh>
    <TabBar :active="active" />
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import { getLocalTime } from '@/utils/validate'
import TabBar from '@/components/tabBar'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
    TabBar,
  },
  filters: {
    getLocalTime
  },
  data() {
    return {
      noLeft: true,
      active: 'new',
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      form: {
        page: 1,
        size: 3,
        LanguageType: null,
      },
    }
  },
  computed: {
    ...mapGetters(['lang']),
  },
  mounted() {
    let LanguageType = ''
    if (this.lang === 'zh') {
      LanguageType = 'Chinese'
    } else if (this.lang === 'en') {
      LanguageType = 'English'
    } else if (this.lang === 'ft') {
      LanguageType = 'Traditional'
    } else {
      LanguageType = 'Japanese'
    }
    this.form.LanguageType = LanguageType
  },
  methods: {
    async getList() {
      const res = await userApi.news(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        data.forEach((item, index) => {
          data[index].Content = data[index].Content.replace(
            /\n/g,
            '<br/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp'
          )
        })
        this.list = [...this.list, ...data]
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }

      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size 12px
  color #ebe5cd
  height 100vh
  padding 73px 0 55px
  .van-pull-refresh
    overflow scroll
  .list-box
    height calc(100vh - 120px)
  .new-list
    padding 15px 25px 37px 40px
    border-bottom 1Px dashed  #4C4F52
    .time
      color #d5ec7b
      line-height 24px
      position relative
      &:before
        width 5px
        height 5px
        border-radius 50%
        background #d5ec7b
        position absolute
        left -15px
        top 9px
        content ''
        display inline-block
    .title
      font-size 15px
      margin-bottom  16px
      font-weight bold
      color #d5ec7b
      line-height 1.2
    .content
      text-indent 2em
      font-size 13px
      color #b9ca79
      line-height 20px
  .importantNews
    .title, .time
      color #f3c206 !important
    .content
      color #eae8df !important
</style>
